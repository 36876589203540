import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenDialogActionButton, ZenDialogDataModel, ZenDialogDataType} from '../zen-dialog/zen-dialog.component';
import {AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {AddressAutocompleteService} from '../../_services/address-autocomplete.services';
import {TagService} from '../../_services/tag.service';
import {LabelledTagOption, Tag, TagType} from '../../../_modules/portfolio/_model/tag.model';
import {
  CustomerDetails,
  PfCustomerDetailsModel,
  PfCustomersContentModel,
  PfCustomersTableRowModel
} from '../../../_modules/portfolio/_model/portfolio-customers.model';
import {CustomerServiceV4} from '../../_services/v4/customer-v4.service';
import {isValidOption} from '../../_zen-legacy-common/_utils/validator-utils';
import {ValidPatterns} from '../../_zen-legacy-common/_models/common/email-pattern';
import {InitialFiltersService} from '../../../_modules/portfolio/_services/_helpers/initial-filters.service';
import {CacheService} from '../../_zen-legacy-common/zen-common-services/_services/cache.service';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {NoSpacesMask, PhoneMask} from '../../_enums/zen-masks.enum';
import {SignupService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/signup.service';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {CustomerAndServiceAddressType} from '../../_zen-legacy-common/_enums/entity-types.enum';
import {EntityTypeService} from '../../_zen-legacy-common/zen-common-services/_services/entity-type.service';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {MatSelectChange} from '@angular/material/select';
import {ChangeStatusObjectType} from '../../../_modules/portfolio/_enums/portfolio-tabs.enum';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {ZenTableMenuOption} from '../../_components/zen-mat-table/zen-mat-table.component';
import {StateV4Service} from '../../_services/v4/state-v4.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-zen-customer-profile-dialog',
  templateUrl: './zen-customer-profile-dialog.component.html',
  styleUrls: ['./zen-customer-profile-dialog.component.scss']
})
export class ZenCustomerProfileDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  ZenDialogDataType = ZenDialogDataType;
  disableButton: boolean;

  unavailableError = 'Unavailable';
  unavailableToCustomerError = 'Unavailable to customer';

  // state
  stateOptions: string[] = [];
  filteredStateOptions: Observable<string[]>;

  customerTypeOptions: CustomerAndServiceAddressType[];

  // tags
  tagFilterCtrl = new UntypedFormControl('');
  tagOptions: LabelledTagOption[] = [];
  selectedTags: Partial<Tag>[] = [];

  isEditMode: boolean;
  isAdvisor: boolean;

  TagType = TagType;
  appearance: MatFormFieldAppearance = 'outline';
  unmask = true; // to remove ext text and special chars from the input.
  NoSpacesMask = NoSpacesMask;
  customerDet: CustomerDetails;
  activatedCustomer = false;
  deactivatedCustomer = false;
  private emailSubscription: Subscription;

  showAgentLanguageNotes = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenCustomerProfileDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private tagService: TagService,
              private formBuilder: UntypedFormBuilder,
              private cacheSvc: CacheService,
              private signupSvc: SignupService,
              private customerServiceV4: CustomerServiceV4,
              private stateV4Svc: StateV4Service,
              public addressAutocompleteService: AddressAutocompleteService,
              private portfolioFiltersService: InitialFiltersService,
              public authSvc: AuthenticationService,
              public translateSvc: TranslateService,
              private pfHelpSvc: PortfolioHelperService) {
    super(translateSvc);
    this.isAdvisor = this.authSvc.isAdvisor();
  }

  ngOnInit(): void {
    this.isEditMode = Boolean(this.data.data && this.data.data.customerId);
    this.stateV4Svc.getAllCountryStates().subscribe({
      next: states => {
        this.stateOptions = states.map(s => s.key);
        this.loadForm();

        // listen to changes in State, and filter options accordingly
        this.filteredStateOptions = this.controls.state.valueChanges.pipe(
          startWith(''),
          map(query => this.filterStates(query)),
        );
      },
      error: err => {
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  get controls() {
    return this.form.controls;
  }

  get primaryContactControls() {
    return (this.form.controls.primaryContact as FormGroup)?.controls;
  }

  get customerId() {
    return this.data.data ? this.data.data.customerId : null;
  }

  loadForm(): void {
    this.initTagOptions();
    this.customerTypeOptions = EntityTypeService.CUSTOMER_AND_SERVICE_ADDRESS_TYPES;
    this.buildForm();

    const customerRowData = this.data.data as PfCustomersTableRowModel;

    if (this.isEditMode) {
      // Fetching customer details
      this.customerServiceV4.getCustomerDetails(customerRowData.customerId).subscribe(customerData => {
        this.customerDet = customerData;
        // Building form for edit
        if (customerData) {
          this.form.controls.customerType.setValue(customerData.type);
          this.form.controls.customerName.setValue(customerData.companyName);
          this.form.patchValue(customerData, {emitEvent: false});
          this.form.controls.zip.setValue(customerData.zip?.trim());
          this.form.controls.zip.updateValueAndValidity();
        }

        // Disable form fields if multi-customer contact, and current user is customer
        if (this.customerDet.primaryContact.multiCustomerContact && !this.authSvc.isAdvisor()) {
          this.getBasicFormFields().forEach(f => f.disable());
          this.primaryContactControls.email.disable();
        }

        // Show agent language notes when agent language required is true on edit
        if (customerData.agentLanguageRequired) {
          this.showAgentLanguageNotes = true;
        }
      }, error => {
        console.log(error);
        this.zenDialogSvc.openToast(false);
      });
    }

    // Email change validation
    this.emailSubscription =  this.getEmailChangeSubscription();
  }

  getEmailChangeSubscription(): Subscription {
    return this.primaryContactControls.email.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(email => {
        if (this.primaryContactControls.email.valid) { // Only check if email is valid
          this.signupSvc.signupEmailCheck(this.primaryContactControls.email.value, null, true).subscribe(res => {
            if (!res.result) {
              this.signupSvc.signupRetrieveUserByEmail(this.primaryContactControls.email.value, null, true).subscribe(user => {
                if (user == null) {
                  if (res.errorMessage === this.unavailableError) {
                    this.primaryContactControls.email.setErrors({existentAccount: true});
                  } else {
                    this.primaryContactControls.email.setErrors({customerExistentAccount: true});
                  }
                } else {
                  this.primaryContactControls.firstName.setValue(user.firstName);
                  this.primaryContactControls.lastName.setValue(user.lastName);
                  this.primaryContactControls.title.setValue(user.title);
                  this.primaryContactControls.phone.setValue(user.phone);
                  this.getBasicFormFields().forEach(field => {
                    // Disable fields if fields are populated
                    if (field.value != null && field.value !== '') {
                      field.disable();
                    } else {
                      field.enable(); // Enable for empty values so we can capture those here
                    }
                  });
                  this.primaryContactControls.userId.setValue(user.id);
                }
              })
            } else {
              if (this.primaryContactControls.firstName.disabled) {
                this.primaryContactControls.firstName.setValue(null);
                this.primaryContactControls.lastName.setValue(null);
                this.primaryContactControls.title.setValue(null);
                this.primaryContactControls.phone.setValue(null);
                this.getBasicFormFields().forEach(field => {
                  field.enable();
                });
              }
              this.primaryContactControls.userId.setValue(null);
            }
          });
        } else {
          // Wipe out values if email is invalid
          this.getBasicFormFields().forEach(field => {
            field.enable();
            field.setValue(null);
          });
        }
      });
  }

  getBasicFormFields(): AbstractControl[] {
    return [this.primaryContactControls.firstName,
      this.primaryContactControls.lastName,
      this.primaryContactControls.title,
      this.primaryContactControls.phone];
  }

  buildForm() {
    this.form = this.formBuilder.group({
      customerType: new UntypedFormControl(CustomerAndServiceAddressType.REAL_ESTATE, [Validators.required]),
      active: new UntypedFormControl(true),
      customerName: new UntypedFormControl(null, [Validators.required]),
      addLen: new UntypedFormControl(false),
      address1: new UntypedFormControl(null, [Validators.required]),
      address2: new UntypedFormControl(null, []),
      state: new UntypedFormControl(null, [Validators.required, isValidOption(this.stateOptions)]),
      city: new UntypedFormControl(null, [Validators.required]),
      zip: new UntypedFormControl(null, [Validators.required]),
      agentLanguageRequired: new UntypedFormControl(false),
      agentLanguageNotes: new UntypedFormControl(null),
      // Primary Contact
      primaryContact: new UntypedFormGroup({
        firstName: new UntypedFormControl(null, [Validators.required]),
        lastName: new UntypedFormControl(null, [Validators.required]),
        title: new UntypedFormControl(null, [Validators.required]),
        email: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]),
        phone: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(16)]),
        userId: new UntypedFormControl(null, [])
      })
    });
  }

  initTagOptions() {
    const obs: Observable<any>[] = [this.tagService.getTagOptions(TagType.CUSTOMER, this.customerId)];
    if (this.customerId) {
      // we are editing a customer, so grab their current tags as well
      obs.push(this.tagService.getTags(TagType.CUSTOMER, this.customerId, this.tagService.getTagExample(this.customerId, TagType.CUSTOMER)));
    }
    combineLatest(obs).subscribe(([options, tags]) => {
      this.tagOptions = options.map(opt => {
        return {
          ...opt,
          label: this.tagService.getTagOptionLabel(opt),
        }
      }).sort((a, b) => a.label.localeCompare(b.label));
      this.selectedTags = tags ? tags : [];
    }, err => {
      console.log(err);
      this.zenDialogSvc.openToast(false);
    });
  }

  handleAddTag(tag: Partial<Tag>) {
    this.selectedTags.push(tag);
    this.tagFilterCtrl.reset('');
  }

  handleRemoveTag(tag: Partial<Tag>) {
    this.selectedTags = this.selectedTags.filter(t => t.name !== tag.name || t.tagGroup?.name !== tag.tagGroup?.name);
  }

  filterStates(query: string) {
    let filteredStates = [...this.stateOptions];
    let search = this.controls.state.value;
    if (!search) {
      return filteredStates;
    } else {
      search = search.toLowerCase();
      return this.stateOptions.filter(s => s.toLowerCase().includes(search));
    }
  }

  handleSelectionChange(event: MatSelectChange) {
    if (this.isEditMode) {
      if (event.source.value === false) {
        this.activatedCustomer = false;
        if (this.customerDet === undefined || this.customerDet?.active === true) {
          this.deactivatedCustomer = true;
        }
      } else {
        this.deactivatedCustomer = false;
        if (this.customerDet === undefined || this.customerDet?.active === false) {
          this.activatedCustomer = true;
        }
      }
    }
  }

  handleAgentLanguageChange() {
    if (this.form.controls.agentLanguageRequired.value === true) { // Agent Language Required = true
      this.showAgentLanguageNotes = true;
    } else { // Agent Language Required = false
      this.showAgentLanguageNotes = false;
    }
  }

  submit(action: ZenDialogActionButton | ZenTableMenuOption) {
    if (action.label === 'Cancel') {
      this.dialogRef.close();
    } else {
      this.form.markAllAsTouched(); // to trigger mat-error
      // creating a customer
      const customer = {
        ...this.form.getRawValue(),
        zip: this.form.value.zip?.trim(),
        tags: this.selectedTags,
        activationChange: {active: this.form.value.active} // Default
      };

      if (this.form.valid && this.isEditMode) {
        this.handleCustomerCreateUpdate(this.customerServiceV4.updateCustomer(this.customerId, customer), null, action);
      } else if (this.form.valid && !this.isEditMode) {
        this.handleCustomerCreateUpdate(this.customerServiceV4.createCustomer(customer), this.controls.addLen.value, action);
      }
    }
  }

  disableDialogActions(disable: boolean) {
    this.disableButton = disable;
  }

  handleCustomerCreateUpdate(sub: Observable<CustomerDetails>, addLen: boolean, action: ZenDialogActionButton | ZenTableMenuOption) {
    this.disableDialogActions(true);
    sub.subscribe(customer => {
      if (this.activatedCustomer) {
        this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText([customer], ChangeStatusObjectType.CUSTOMER));
      } else if (this.deactivatedCustomer) {
        this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText([customer], ChangeStatusObjectType.CUSTOMER));
      } else {
        this.zenDialogSvc.openToast(true);
      }

      this.pfHelpSvc.handleDetailsEntityStatusUpdate(this.controls.active.value);

      this.cacheSvc.clearCacheByName('customer'); // Clear customer-list cache from the old ui
      this.disableDialogActions(false);
      action.command({addLen, customer});
    }, error => {
      console.log(error);
      this.disableDialogActions(false);
      this.zenDialogSvc.openToast(false);
    });
  }

  hasError(formControlName: string, formErrorName: string, isPrimaryContact?: boolean): boolean {
    return zenHasError(isPrimaryContact ? (this.form.controls.primaryContact as FormGroup) : this.form, formControlName, formErrorName);
  }
}
