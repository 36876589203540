import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ValidPatterns} from '../../_zen-legacy-common/_models/common/email-pattern';
import {ProfileService} from '../../_zen-legacy-common/zen-common-services/_services/profile.service';
import {MustMatch} from '../../_zen-legacy-common/_utils/mustmatch-utils';
import {SignupService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/signup.service';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {NoSpacesMask} from '../../_enums/zen-masks.enum';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {PasswordConfig} from '../../_zen-legacy-common/_models/organization';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CustomerContactService} from '../../_zen-legacy-common/zen-common-services/_services/customer-contact.service';
import {CustomerIdSessionService} from '../../_services/customer-id/customer-id-session.service';
import {CustomerContact} from '../../_zen-legacy-common/_models/customer';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zen-edit-customer-member-dialog',
  templateUrl: './zen-edit-customer-member-dialog.component.html',
  styleUrls: ['./zen-edit-customer-member-dialog.component.scss']
})
export class ZenEditCustomerMemberDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  loading: boolean;
  editContact: CustomerContact;
  appearance: MatFormFieldAppearance = 'outline';

  unavailableError = 'Unavailable';
  unavailableToCustomerError = 'Unavailable to customer';

  // Phone Number Mask
  // Ref: https://imask.js.org/guide.html
  // lazy: false,  // make placeholder always visible
  unmask = true; // to remove ext text and special chars from the input.

  NoSpacesMask = NoSpacesMask;
  passwordConfig: PasswordConfig;
  errorMsg: string;
  isEditMode: boolean;
  isPrimaryContact: boolean;
  customerId: number;

  uneditedEmailValue: string;

  constructor(public dialogRef: MatDialogRef<ZenEditCustomerMemberDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: ZenDialogDataModel,
              private zenDialogSvc: ZenDialogMsgService, public orgMgtSvc: OrganizationManagementService,
              private profileSvc: ProfileService, private signupSvc: SignupService,
              private formBuilder: UntypedFormBuilder, private customerContactSvc: CustomerContactService,
              private customerIdSvc: CustomerIdSessionService,
              public translateSvc: TranslateService
  ) {
    super(translateSvc);
    // customerId store updated from CustomerIdSessionStore using RouterQuery
    this.customerId = customerIdSvc.getCustomerId();
    this.isEditMode = Boolean(this.dialogData.data?.contact);
    this.isPrimaryContact = Boolean(this.dialogData.data?.contact?.primary);
  }

  ngOnInit(): void {
    this.orgMgtSvc.organizationSubject.subscribe(org => {
      this.passwordConfig = org.settings.passwordConfig;
      this.loadForm();
    });


  }

  get controls() {
    return this.form.controls;
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      title: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(16)]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]),
      // If allowLogin is set to true password field will be displayed.
      allowLogin: new UntypedFormControl(false),
      primary: new UntypedFormControl(false),
      password: new UntypedFormControl(null),
      confirmPassword: new UntypedFormControl(null),
      userId: new UntypedFormControl(null),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    // If someone is the primary contact, don't allow them to unset these fields,
    // Because it can leave them in a state with no login, and no primary user
    if (this.isPrimaryContact) {
      this.controls.primary.setValue(true);
      this.controls.primary.disable();
      this.controls.allowLogin.setValue(true);
      this.controls.allowLogin.disable();
    }

    // If the user is not allowed to login, they cannot be the primary contact
    if (this.form.controls.allowLogin.value !== true) {
      this.controls.primary.setValue(false);
      this.controls.primary.disable();
    }


    // Password field validation is set w.r.t allowLogin
    this.controls.allowLogin.valueChanges.subscribe((allowLogin: boolean) => {
      if (allowLogin) {
        this.signupSvc.validateExistentAccount(this.form.controls.email, this.editContact?.email, true)
        this.controls.password.reset();
        this.controls.password.setValidators([Validators.required, Validators.pattern(this.passwordConfig.pattern)]);
        // If this isn't already the primary contact, allow the user to choose to be the primary contact
        if (!this.isPrimaryContact) {
          this.controls.primary.setValue(false);
          this.controls.primary.enable();
        }
      } else {
        this.controls.password.reset();
        this.controls.password.setValidators(null);
        this.controls.primary.setValue(false);
        this.controls.primary.disable();
      }
      this.controls.password.updateValueAndValidity();
    });


    // Email change validation
    this.controls.email.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(email => {
        if (this.form.controls.email.valid && (email !== this.uneditedEmailValue)) { // Only check if email is valid
          this.signupSvc.signupEmailCheck(this.form.controls.email.value).subscribe(res => {
            if (!res.result) {
              this.signupSvc.signupRetrieveUserByEmail(this.form.controls.email.value, null, true).subscribe(user => {
                if (user != null) {
                  if (res.errorMessage === this.unavailableError) {
                    this.controls.email.setErrors({existentAccount: true});
                    this.controls.userId.setValue(null);
                  } else if (res.errorMessage === this.unavailableToCustomerError) {
                    this.controls.email.setErrors({customerExistentAccount: true});
                    this.controls.userId.setValue(null);
                  } else {
                    this.controls.firstName.setValue(user.firstName);
                    this.controls.lastName.setValue(user.lastName);
                    this.controls.title.setValue(user.title);
                    this.controls.phone.setValue(user.phone);
                    this.controls.userId.setValue(user.id);

                    this.getBasicFormFields().forEach(field => {
                      if (field.value != null && field.value !== '') {
                        field.disable();
                      } else {
                        field.enable(); // Enable for empty values so we can capture those here
                      }
                    })
                    this.controls.allowLogin.disable();
                    this.controls.allowLogin.setValue(null);
                  }
                }
              })
            } else {
              if (this.controls.firstName.disabled) {
                // Enable and wipe out values
                this.getBasicFormFields().forEach(field => {
                  field.enable();
                  field.setValue(null);
                });

                this.controls.allowLogin.enable();
                this.controls.allowLogin.setValue(true);
              }
              this.controls.userId.setValue(null);
            }
          });
        } else {
          // Wipe out values if email is invalid
          this.getBasicFormFields().forEach(field => {
            field.enable();
            field.setValue(null);
          });
          this.controls.allowLogin.enable();
          this.controls.allowLogin.setValue(false);
        }
      });

    // Edit customer member
    if (this.isEditMode) {
      this.loadUserData();
      this.toggleInitialPrimarySetting();
    } else {
      this.controls.primary.setValue(false);
      this.controls.primary.disable();

    }

  }

  getBasicFormFields(): AbstractControl[] {
    return [this.controls.firstName,
      this.controls.lastName,
      this.controls.title,
      this.controls.phone];
  }

  loadUserData() {
    const contact = this.dialogData.data?.contact;
    this.editContact = contact;
    this.form.patchValue(contact, {emitEvent: false});
    this.uneditedEmailValue = this.controls.email.value;

  }

  toggleInitialPrimarySetting() {
    const contact = this.dialogData.data?.contact;
    // After patching values update primary if necessary
    if (contact.allowLogin && !this.isPrimaryContact) {
      this.controls.primary.setValue(false);
      this.controls.primary.enable();
    }
  }

  submit() {
    this.errorMsg = null;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.loading = true;
      if (this.form.value.allowLogin && !this.isSameEmailId) {
        this.signupSvc.signupEmailCheck(this.controls.email.value).subscribe(res => {
          if (!res.result) {
              this.controls.email.setErrors({existentAccount: true});
              this.zenDialogSvc.openToast(false, 'Email exists.');
          } else {
              this.handleSubmitApiCalls();
          }
          });
      } else {
        this.handleSubmitApiCalls();
      }
    } else {
      this.zenDialogSvc.openToast(false, ZenErrorMsgEnum.ERR_FORM_FIELD);
      if (this.controls.password.errors && this.controls.password.errors.pattern) {
        this.errorMsg = this.passwordConfig.description;
      }
      if (this.controls.confirmPassword.errors && this.controls.confirmPassword.errors.mustMatch) {
        this.errorMsg = ZenErrorMsgEnum.PASSWORD_MATCH;
      }
      if (this.controls.email.errors) {
        this.errorMsg = ZenErrorMsgEnum.EMAIL_EXISTS;
      }
      this.loading = false;
    }
  }

  handleSubmitApiCalls() {
    let {id, firstName, lastName, email, phone, title, allowLogin} = this.form.value;
    // Add customer member
    if (!this.isEditMode) {
      // Adding customer member
      this.customerContactSvc.addContactByCustomerId(this.buildUserSubmitData(), this.customerId).subscribe(contact => {
        // If allow user login is present
        this.loading = false;
        this.zenDialogSvc.openToast(true);
        this.dialogRef.close(contact);
      }, e => {
        this.loading = false;
        console.log('Error: Add contact ', e);
        this.zenDialogSvc.openToast(false);
      });
    } else { // Edit contact user
      const _contactId = this.dialogData.data?.contact?.id;
      this.customerContactSvc.updateContactById(_contactId, this.buildUserSubmitData(), this.customerId).subscribe(user => {
        this.loading = false;
        this.dialogRef.close({firstName, lastName, email, phone, title});
      }, e => {
        console.log('Error: Update contact -> user ', e);
        this.loading = false;
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        this.zenDialogSvc.openToast(false);
      });
    }
  }

  buildUserSubmitData(): CustomerContact {

    let {id, firstName, lastName, email, title, phone, password, allowLogin, userId, primary} = this.form.getRawValue();
    let _data: CustomerContact = {id, firstName, lastName, email, title, phone, allowLogin, userId, primary};
    if (this.controls.password.dirty && allowLogin && password) {
      _data.passwordBase64 = password ? btoa(password).replace(/\=/g, '.') : null;
    }
    return _data;
  }

  get isSameEmailId() {
    return Boolean(this.editContact?.email && this.controls.email.value?.trim()?.toLowerCase() === this.editContact?.email?.trim()?.toLowerCase())
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
