<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [disableButton]="disableButton"
  [loading]="disableButton">
  <div class="d-flex flex-wrap" *ngIf="form">
    <div class="col-12 col-md-6 px-4">
      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form zen-customer-contact-form">
          <div class="d-flex justify-content-center">
            <div class="justify-content-center" *ngIf="form" [formGroup]="form">

              <div class="row mb-3">
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <p class="form-title mb-0">{{isAdvisor ? 'Customer' : 'Company'}} Details</p>
                </div>
              </div>

              <!-- Customer details form -->
              <div class="row mt-1 text-start">
                <div class="col-12" [ngClass]="isEditMode ? 'form-group' : 'mb-3'">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{authSvc.customerNameLabel}}*</mat-label>
                    <input matInput formControlName="customerName">
                    <mat-error *ngIf="hasError('customerName', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                  <div *ngIf="!isEditMode" class="d-flex align-items-center justify-content-end">
                    <mat-checkbox class="mt-0 ms-0" formControlName="addLen">Save as Legal Entity Name</mat-checkbox>
                    <i class="material-icons-outlined help-outline ms-2 mt-1"
                       [popper]="saveLenPopper"
                       [popperHideOnScroll]="true"
                       [popperHideOnClickOutside]="true"
                       popperTrigger="click"
                       popperApplyClass="sm"
                       popperPlacement="top">info</i>
                  </div>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Physical Address 1*</mat-label>
                    <input matInput formControlName="address1" #address
                           (keyup)="addressAutocompleteService.handleAddressChange(form, address)">
                    <mat-error *ngIf="hasError('address1', 'required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Physical Address 2</mat-label>
                    <input matInput formControlName="address2">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Physical City*</mat-label>
                    <input matInput formControlName="city">
                    <mat-error *ngIf="hasError('city','required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Physical {{'nomenclature.state' | translate}}*</mat-label>
                    <input matInput [formControl]="controls.state" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="100" [autoActiveFirstOption]="true">
                      <mat-option *ngFor="let option of filteredStateOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('state', 'required')">Required Field*</mat-error>
                    <mat-error *ngIf="hasError('state', 'validoption')">Invalid {{'nomenclature.state' | translate}}*</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Physical Zip*</mat-label>
                    <input matInput formControlName="zip" [mask]="i18nMask.zip">
                    <mat-error *ngIf="hasError('zip', 'required')">Required Field*</mat-error>
                    <mat-error *ngIf="hasError('zip', 'pattern')">Invalid Format*</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Customer Type*</mat-label>
                    <mat-select formControlName="customerType">
                      <mat-option *ngFor="let opt of customerTypeOptions" [value]="opt"
                                  class="py-2">{{opt}}</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('customerType', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Activation Status*</mat-label>
                    <mat-select formControlName="active" (selectionChange)="handleSelectionChange($event)">
                      <mat-option [value]="true" class="py-2">Active</mat-option>
                      <mat-option [value]="false" class="py-2">Inactive</mat-option>
                    </mat-select>
                    <mat-hint *ngIf="activatedCustomer" class="mt-2 text-color">
                      You are about to <b>reactivate</b> this customer. The platform will also <b>reactivate</b> the underlying records.</mat-hint>
                    <mat-hint *ngIf="deactivatedCustomer" class="mt-0 text-color">
                      You are about to <b>deactivate</b> this customer. Any underlying records associated with your customer will also be <b>deactivated</b>.</mat-hint>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('active', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                    <mat-slide-toggle formControlName="agentLanguageRequired" (change)="handleAgentLanguageChange($event)">
                      Agent Language Required
                    </mat-slide-toggle>
                </div>
                <ng-container *ngIf="showAgentLanguageNotes">
                  <div class="col-12 form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>Notes</mat-label>
                      <input matInput formControlName="agentLanguageNotes">
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-12 col-md-6 px-4">
      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form zen-customer-contact-form">
          <div class="d-flex justify-content-center">
            <div class="justify-content-center" *ngIf="form.controls.primaryContact" [formGroup]="form.controls.primaryContact">

              <div class="row mb-3">
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <p class="form-title mb-0">Primary Contact</p>
                </div>
              </div>
              <!-- Contact form -->
              <div class="row mt-1 text-start">
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Email*</mat-label>
                    <input matInput formControlName="email" [imask]="NoSpacesMask" [unmask]="unmask">
                    <mat-error *ngIf="hasError('email', 'required', true)">Required Field*
                    </mat-error>
                    <mat-error *ngIf="hasError('email', 'pattern', true)">Invalid Format*
                    </mat-error>
                    <mat-error *ngIf="hasError('email', 'existentAccount', true)">Email already exists*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>First Name*</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-error *ngIf="hasError('firstName', 'required', true)">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Last Name*</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-error *ngIf="hasError('lastName', 'required', true)">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Title*</mat-label>
                    <input matInput formControlName="title">
                    <mat-error *ngIf="hasError('title', 'required', true)">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Phone*</mat-label>
                    <input matInput formControlName="phone" [imask]="i18nMask.phone" [unmask]="i18nMask.phoneUnmask">
                    <mat-error *ngIf="hasError('phone', 'required', true)">Required Field*
                    </mat-error>
                    <mat-error *ngIf="(hasError('phone', 'minlength', true)
                    || hasError('phone', 'maxlength', true))">Invalid Format*</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form">
          <app-zen-tags
            [type]="TagType.CUSTOMER"
            [tagFilterCtrl]="tagFilterCtrl"
            [tagOptions]="tagOptions"
            [selectedTags]="selectedTags"
            (onAddTag)="handleAddTag($event)"
            (onRemoveTag)="handleRemoveTag($event)"></app-zen-tags>
        </div>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>

<popper-content #saveLenPopper>
  <app-zen-popper title="Saving as Legal Entity Name" [closeable]="true" [popperContent]="saveLenPopper">
    <p class="medium">
      Larger customers require a more generic Customer Name to act as an umbrella for each underlying Legal Entity Name
      and/or Service Address while smaller customers, with a single service address, often save the Customer Name as the Legal Entity Name.
    </p>
  </app-zen-popper>
</popper-content>
