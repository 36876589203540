import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {RateCheckV4Service} from '../../_services/v4/rate-check-v4.service';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenTableColsModel, ZenTableConfig} from '../../_components/zen-mat-table/zen-mat-table.component';
import {PortfolioTabTopActionConfig} from '../../_components/zen-tab-top-action/zen-tab-top-action.component';
import {UntypedFormControl} from '@angular/forms';
import {ZenTableViewTypeEnum} from '../../_enums/zen-table-view-type.enum';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {Router} from '@angular/router';
import {NavigationService} from '../../_zen-legacy-common/zen-common-services/_services/navigation.service';
import {PortfolioHierarchyLevelEnum} from '../../../_modules/portfolio/_enums/portfolio-hierarchy-level.enum';
import {ZenBulkImportTableHelperService} from '../../_services/helpers/zen-bulk-import-table-helper.service';
import {DataFlowChildrenDataTableModified} from '../../_model/data-flow-hierarchy-v4.model';
import {RateCheckV3Model} from '../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {formatExtractFileName} from '../../_utils/zen-filename.util';
import {DownloadService} from '../../_zen-legacy-common/zen-common-services/_services/download.service';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';

@Component({
  selector: 'app-zen-meters-tree-table-dialog',
  templateUrl: './zen-meters-tree-table-dialog.component.html',
  styleUrls: ['./zen-meters-tree-table-dialog.component.scss']
})
export class ZenMetersTreeTableDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  loading: boolean;
  meterListModified: DataFlowChildrenDataTableModified[] = [];
  tableConfig: ZenTableConfig;
  tableTopConfigBtnAction: PortfolioTabTopActionConfig;
  baseUnit: ZenUnitsEnum;
  commodity: CommodityType;
  rateCheck: RateCheckV3Model;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenMetersTreeTableDialogComponent>,
              private rcV4Svc: RateCheckV4Service,
              private router: Router,
              private navSvc: NavigationService,
              private downloadSvc: DownloadService,
              private pfHelpSvc: PortfolioHelperService,
              public translateSvc: TranslateService,
              private zenBulkImportTableHelperSvc: ZenBulkImportTableHelperService,
              private zenDialogSvc: ZenDialogMsgService
              ) {
    super(translateSvc);
  }

  ngOnInit() {
    this.rateCheck = this.dialogData?.data?.rateCheck;
    this.commodity = this.rateCheck.commodityType;

    this.baseUnit = this.commodity as CommodityType === CommodityType.Electricity ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth;
    if (this.rateCheck) {
      this.loading = true;
      this.rcV4Svc.getRcMetersDataFlowDetails(this.rateCheck.customerId, this.rateCheck.id, true).subscribe({
        next: metersDetails => {
          this.meterListModified = metersDetails.map(d => this.zenBulkImportTableHelperSvc.getChildDataChildren(d));
          this.buildTable();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.zenDialogSvc.openToast(false);
        }
      });
    }
  }

  buildTable() {
    const rateClassFieldFilled = this.meterListModified.some(meter => meter.rateScheduleCode !== null);
    const zoneFieldFilled =  this.commodity == CommodityType.Electricity && this.meterListModified.some(meter => meter.zoneName !== null);

    this.tableConfig = {
      hideMultiselect: true,
      cols: [
        {
          field: 'utilityAccountNum1', subTextCol: 'meterDetSubText', header: 'Meter', type: 'standard',
          colStyleCls: 'text-column', hyperlink: true,
          handleHyperLinkClick: (col: ZenTableColsModel, rowData: DataFlowChildrenDataTableModified) =>
            this.gotoDetailsPage(PortfolioHierarchyLevelEnum.METERS, rowData, false),
          handleHyperLinkRightClick: (col: ZenTableColsModel, rowData: DataFlowChildrenDataTableModified) =>
            this.gotoDetailsPage(PortfolioHierarchyLevelEnum.METERS, rowData, true)
        },
        {
          field: 'serviceAddressName', header: 'Service Address', type: 'standard', subTextCol: 'serviceAddress',
          colStyleCls: 'text-column', hyperlink: true,
          handleHyperLinkClick: (col: ZenTableColsModel, rowData: DataFlowChildrenDataTableModified) =>
            this.gotoDetailsPage(PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES, rowData, false),
          handleHyperLinkRightClick: (col: ZenTableColsModel, rowData: DataFlowChildrenDataTableModified) =>
            this.gotoDetailsPage(PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES, rowData, true)
        },
        {
          field: 'lenName', header: 'LEN', type: 'standard', colStyleCls: 'text-column', hyperlink: true,
          handleHyperLinkClick: (col: ZenTableColsModel, rowData: DataFlowChildrenDataTableModified) =>
            this.gotoDetailsPage(PortfolioHierarchyLevelEnum.LENS, rowData, false),
          handleHyperLinkRightClick: (col: ZenTableColsModel, rowData: DataFlowChildrenDataTableModified) =>
            this.gotoDetailsPage(PortfolioHierarchyLevelEnum.LENS, rowData, true)
        },
        {field: 'meterUtilityName', header: this._translations?.nomenclature?.utilityShort, subTextCol: 'rateScheduleCode', type: 'standard'},
        {
          field: 'annualUsageText', header: `Annual Usage (${this.baseUnit})`, align: 'center', type: 'standard',
          sortColumnName: 'annualUsage'
        },
        {
          field: 'rateScheduleCode', header: `Rate Class`, align: 'center', type: 'standard',
          hidden: !rateClassFieldFilled || !this._translations?.features?.featureRateSchedules
        },
        {field: 'zoneName', header: `Zone`, align: 'center', type: 'standard', hidden: !zoneFieldFilled},
        {
          field: 'currentContractText', header: 'Current Contract', subTextCol: 'currentContractSubText',
          type: 'standard', colStyleCls: 'text-column',
        },
        {field: 'proposedStartFormatted', header: 'Proposed Start', align: 'center', type: 'standard', colStyleCls: 'text-column'},
        {field: 'menu', header: '', type: 'menu'}
      ],
      download: {
        enable: true,
        getFileNameFn: () => this.pfHelpSvc.getTableDownloadFileName('portfolio_meters'),
        externalDownload: () => this.handleExport(this.meterListModified)
      },
      rowMenuOptions: [
        {label: 'Export Row', type: 'button', command: (rowData: DataFlowChildrenDataTableModified) => this.handleExport([rowData])}
      ]
    };

    this.tableTopConfigBtnAction = {
      isDialog: true,
      btnToggleGroupAction: {
        formCtrl: new UntypedFormControl(ZenTableViewTypeEnum.COLLAPSED),
        onChange: () => null,
        options: [
          {label: '', value: ZenTableViewTypeEnum.DETAILED, icon: ZenIconsEnum.DETAILED},
          {label: '', value: ZenTableViewTypeEnum.COLLAPSED, icon: ZenIconsEnum.COLLAPSED}
        ]
      }
    };
  }

  async handleExport(exportData: DataFlowChildrenDataTableModified[]) {
    let _columns: ZenTableColsModel[] = [...this.tableConfig.cols].filter(c => {
      return c.type !== 'menu' && !c.hidden;
    });

    let _data: any[] = [...exportData]; // pull from current page

    // Setting meterName for the first column.
    _columns[0] = {field: 'meterName', header: 'Meter', type: 'standard'};

    // Adding additional columns to support download requirement.
    _columns.splice(1, 0, {header: 'UAN 1', field: 'utilityAccountNum1', type: 'standard'});
    if (this.meterListModified.some(m => m.utilityAccountNum2)) {
      _columns.splice(2, 0, {header: 'UAN 2', field: 'utilityAccountNum2', type: 'standard'});
    }

    if (this.rateCheck.state !== 'TX' && this.commodity === CommodityType.Electricity
      && this._translations?.features?.featureRateSchedules) {
      _columns.splice(6, 0, {header: 'Rate Class', field: 'rateScheduleCode', type: 'standard'});
    }

    // build the data to be exported for each row in the table
    const exportRows = _data.map(row => {
      const exportRow = {};
      _columns.forEach(col => {
        if (col.downloadFormatter) {
          exportRow[col.header] = col.downloadFormatter(col, row);
        } else {
          exportRow[col.header] = col.formatter ? col.formatter(col, row) : row[col.field];
        }
      });
      return exportRow;
    });
    const fileName = formatExtractFileName(this.tableConfig.download.getFileNameFn());
    this.downloadSvc.downloadExcelFile(exportRows, fileName);
  }

  gotoDetailsPage(hierarchy: PortfolioHierarchyLevelEnum, rowData: DataFlowChildrenDataTableModified, rightClick: boolean) {
    let _routes = [];
    switch (hierarchy) {
      case PortfolioHierarchyLevelEnum.METERS:
        _routes = this.navSvc.getPortfolioMeterDetailsPageRoute(this.rateCheck.customerId, rowData.serviceAddressId, rowData.meterId);
        break;
      case PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES:
        _routes = this.navSvc.getPortfolioServiceAddressDetailsPageRoute(this.rateCheck.customerId, rowData.serviceAddressId)
        break;
      case PortfolioHierarchyLevelEnum.LENS:
        _routes = this.navSvc.getPortfolioLenDetailsPageRoute(this.rateCheck.customerId, rowData.lenId);
        break;
    }

    if (rightClick) {
      window.open(this.navSvc.toRouterLink(_routes));
    } else {
      this.router.navigate(_routes);
      this.dialogRef.close();
    }
  }

  submit(btn: ZenDialogActionButton) {
    btn.command(btn);
  }

}
